


import {Component, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {TNote} from '@/_types/store/note.type';
import {Validations} from 'vuelidate-property-decorators';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import {maxLength, required} from 'vuelidate/lib/validators';
import iconShare from '@/_modules/icons/components/icon-share.vue';
import iconStar from '@/_modules/icons/components/icon-star.vue';
import switchCheckbox from '@/views/components/parts/switch-checkbox.vue';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import {TRequestNote} from '@/_api/notes/notes.api';
import {TUser} from '@/_types/user.type';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import {TContact} from '@/_types/contact.type';
import {Action} from 'vuex-class';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';

const NoAvatarImageUser = require('@/assets/images/no-avatar-60x60.svg');
type TNoteQuery = {
  offset: number;
  limit: number;
}

@Component({
  components: {iconShare, iconStar, switchCheckbox},
  computed: {
    ...mapGetters({
      userInfo: '_userStore/user',
      contact: 'promoPageStore/contact',
      noteList: 'notesStore/noteList',
      isNoteLoading: 'notesStore/isNoteLoading',
      listTotal: 'notesStore/noteList',
    }),
  }
})
export default class Notes extends Vue {

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName?: string}) => void;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  public noteList: TApiListResponse<TNote>;
  public contact: TContact;
  public userInfo: TUser;
  public isNoteLoading: boolean;
  public sharerPreviousId: number;

  @Validations()
  public readonly validations: TVuelidateRuleSet<TNote> = {
    formData: {
      title: {
        required,
      },
      text: {
        required,
        maxLength: maxLength(2000)
      },
    },
    createFormData: {
      title: {
        required,
      },
      text: {
        required,
        maxLength: maxLength(2000)
      },
    },
  }

  public toggledId: number = null;
  public isCollapsed: boolean = true;
  public create: boolean = false;
  public edit: boolean = false;
  public noteId: number = null;
  public shareColor: string = '#707070';
  public isActive: boolean = false;
  public query: TNoteQuery = {
    offset: 0,
    limit: 10,
  };
  public type: string = 'all';
  public totalPages: number = 0;
  public tab: number = 0;
  public formData: TRequestNote = {
    title: '',
    text: '',
    is_private: true,
  };
  public createFormData: TRequestNote = {
    title: '',
    text: '',
    is_private: true,
  };
  public user_id: number = null;
  public isSendLoading: boolean = false;
  public onChangeLoading: boolean = false;

  public get getNoteList(): TApiListResponse<TNote> {
    return this.noteList;
  }

  public get isLoading(): boolean {
    return this.isNoteLoading;
  }

  public get getEventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  @Watch('edit', {deep: true})
  public onEditChange(): void {
    if (this.edit) {
      this.setData();
    }
  }

  @Watch('userInfo', {immediate: true})
  public onUserInfoChange(): void {
    if (!this.userInfo || !this.userInfo.id) {
      return;
    }
    this.callNoteList();
    this.user_id = this.userInfo.id;
  }

  public setNoteIsPrivate($event: Event): void {
    this.createFormData.is_private = !$event;
  }

  public async callNoteList(): Promise<void> {
    if (this.type === 'own') {
      await this.$store.dispatch('notesStore/notesMy', {
        queryParams: {...this.query}, eventId: this.getEventId
      });
    } else if (this.type === 'all') {
      await this.$store.dispatch('notesStore/notesAll', {
        queryParams: {...this.query}, eventId: this.getEventId
      });
    }

    this.mode(false, false, null);
  }

  public mode(create: boolean, edit: boolean, noteId: number): void {
    this.create = create;
    this.edit = edit;
    this.noteId = noteId;
  };

  public setAvatar(url: string): string {
    return url || NoAvatarImageUser;
  }

  public async addFav(id: number): Promise<void> {
    await this.$store.dispatch('notesStore/addFav', id);
    await this.callNoteList();
  }

  public async removeFav(id: number): Promise<void> {
    await this.$store.dispatch('notesStore/removeFav', id);
    await this.callNoteList();
  }

  public async remove(id: number): Promise<void> {
    this.noteId = id;
    this.onChangeLoading = true;
    await this.$store.dispatch('notesStore/removeNote', id);
    await this.callNoteList();
    this.onChangeLoading = false;
  }

  public transformDate(val: Date): string {
    return this.$moment(this.$moment(val)).format('DD MMM YYYY');
  }

  public transformTime(val: Date): string {
    return this.$moment(this.$moment(val)).format('HH:mm');
  }

  public setTab(val: number): void {
    this.tab = Number(val);
    if (this.tab === 0 || !this.tab) {
      this.type = 'all';
      this.query.offset = 0;
      this.query.limit = 10;
      this.callNoteList();
    }
    if (this.tab === 1) {
      this.type = 'own';
      this.query.offset = 0;
      this.query.limit = 10;
      this.callNoteList();
    }
  };

  public getTruncatedText(text: string, length: number): string {
    if (!text || !length || length <= 0 || text.length <= length) {
      return text;
    }
    return `${text.substring(0, length)}...`;
  }

  public linkifyUrls(inputString: string): string {
    return ChatHelper.createLinks(inputString);
  }

  public async editNote(): Promise<void> {
    this.$v.formData.$touch();

    if (this.$v.formData.$pending || this.$v.formData.$invalid) {
      return;
    }

    this.onChangeLoading = true;
    await this.$store.dispatch('notesStore/editNote', {queryParams: this.formData, noteId: this.noteId});
    await this.callNoteList();
    this.edit = false;
    this.onChangeLoading = false;
  }

  public setData(): void {
    const note = this.getNoteList.List.filter((item) => {
      return item.id === this.noteId;
    });
    // this.formData.note_id = note[0].id;
    this.formData.title = note[0].title;
    this.formData.text = note[0].text;
    this.formData.is_private = note[0].is_private;
  }

  public async createNote(): Promise<void> {
    this.$v.createFormData.$touch();

    if (this.$v.createFormData.$pending || this.$v.createFormData.$invalid) {
      return;
    }

    this.isSendLoading = true;
    await this.$store.dispatch('notesStore/addNote', {eventId: this.getEventId, queryParams: this.createFormData});
    await this.callNoteList();
    this.isSendLoading = false;
    this.createFormData.title = '';
    this.createFormData.text = '';
    this.createFormData.is_private = false;
    this.create = false;
  }

  public setShareNoteId(id: number): void {
    this.noteId = id;
  }

  public removeShareNoteId(): void {
    if (!this.edit) {
      this.noteId = null;
    }
  }

  public share(event: KeyboardEvent, id: number): void {
    if (this.sharerPreviousId === id) {
      this.closeSharer();
    } else {
      this.openSharer({
        eventTarget: event.target as Element,
      });
    }
    this.sharerPreviousId = id;
  }

  public loadMore(): void {
    if (this.query.limit <= this.getNoteList.Total) {
      this.query.limit += 10;
      this.callNoteList();
    }
  }

  public toContact(contactId: number): void {
    // Don't let clicking on own note's card_head
    if (this.contact.id === contactId) {
      return;
    }

    this.openContactCard({
      contactId,
    });
  }

  public showText(id: number): void {
    this.toggledId = id;
  }
  public hideText(): void{
    this.toggledId = null;
  }

}
